import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthenticateRoute } from "./Component/AuthenticateRoute";
import { Authentication } from "./Screen/Authentication";
import { Home } from "./Screen/Home";
import { Login } from "./Screen/Login";
import { Logout } from "./Screen/Logout";
import { Notfound } from "./Component/Notfound";
import Orders from "./Screen/Orders";
import {
  PERMISSION_ORDER_DETAIL_VIEW,
  PERMISSION_ORDERS_VIEW,
  PERMISSION_PACKAGE_ORDERS_VIEW,
  PERMISSION_CUSTOMER_LIST_VIEW,
  PERMISSION_CUSTOMER_DETAIL_VIEW,
  PERMISSION_PACKAGE_PREFERENCE,
} from "./Config/permission";
import OrderDetail from "./Screen/OrderDetail";
import Customers from "./Screen/Customers";
import CustomerDetail from "./Screen/Customers/Details";
import PackageOrders from "./Screen/PackageOrders";
import Preferences from "./Screen/Preferences/Preferences";

export default class AppRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Routes>
          <Route
            path="/"
            element={
              <AuthenticateRoute>
                <Home />
              </AuthenticateRoute>
            }
          />

          <Route path={"/login"} element={<Login />} />
          <Route path={"/logout"} element={<Logout />} />
          <Route path={"/authentication"} element={<Authentication />} />
          <Route
            path="/orders"
            element={
              <AuthenticateRoute permission={PERMISSION_ORDERS_VIEW}>
                <Orders />
              </AuthenticateRoute>
            }
          />
          <Route
            path={"/orders/:code"}
            element={
              <AuthenticateRoute permission={PERMISSION_ORDER_DETAIL_VIEW}>
                <OrderDetail />
              </AuthenticateRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <AuthenticateRoute permission={PERMISSION_CUSTOMER_LIST_VIEW}>
                <Customers />
              </AuthenticateRoute>
            }
          />
          <Route
            path="/customers/:username"
            element={
              <AuthenticateRoute permission={PERMISSION_CUSTOMER_DETAIL_VIEW}>
                <CustomerDetail />
              </AuthenticateRoute>
            }
          />
          <Route
            path={"/package-orders"}
            element={
              <AuthenticateRoute permission={PERMISSION_PACKAGE_ORDERS_VIEW}>
                <PackageOrders />
              </AuthenticateRoute>
            }
          />
          <Route
            path={"/preferences"}
            element={
              <AuthenticateRoute permission={PERMISSION_PACKAGE_PREFERENCE}>
                <Preferences />
              </AuthenticateRoute>
            }
          />

          <Route path={"*"} element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
